/*
* 语言文件 en：英文
* */
export const lang_en = {
    '账号安全': '',
    '尚未绑定手机号': '',
    '修改号码': '',
    '绑定号码': '',
    '电子邮箱': '',
    '绑定邮箱：': '',
    '若邮箱已停用，请及时更换': '',
    '尚未绑定邮箱': '',
    '手机号码': '',
    '绑定邮箱': '',
    '登录密码': '',
    '修改密码': '',
    '设置密码': '',
    '修改邮箱': '',
    '绑定手机：': '',
    '若手机丢失或停用，请及时更换': '',
    '重置密码': '',
    '地址管理': '',
    '默认地址': '',
    '新增地址': '',
    '全部地址': '',
    '设置为默认': '',
    '编辑地址': '',
    '删除地址': '',
    '暂无收货地址～': '',
    '添加收货地址': '',
    '收货人': '',
    '请输入收货人姓名': '',
    '请输入收货人': '',
    '电话号码': '',
    '在使用账户余额时需输入支付密码以保证您的资金安全': '',
    '所在地区': '',
    '请选择所在地区': '',
    '详细地址': '',
    '请输入详细地址': '',
    '设为默认地址': '',
    '取 消': '',
    '确 定': '',
    '我的退货': '',
    '我的退款': '',
    '商品明细': '',
    '申请时间': '',
    '申请类型': '',
    '状态': '',
    '操作': '',
    '下单时间': '',
    '订单号': '',
    '查看详情': '',
    '发货': '',
    '我已知悉？': '',
    '去注册': '',
    '添加/编辑': '',
    '（同订单商品可批量申请）': '',
    '支付密码': '',
    '退款金额不可修改，最多': '',
    '含运费': '',
    '不含运费': '',
    '修改金额': '',
    '退款金额可修改，最多': '',
    '请输入退款金额': '',
    '取消': '',
    '确定': '',
    '申请件数': '',
    '请输入申请件数': '',
    '货物状态': '',
    '退款原因': '',
    '退货原因': '',
    '请选择退款原因': '',
    '请选择退货原因': '',
    '问题描述': '',
    '请输入问题描述(选填)': '',
    '上传凭证': '',
    '批量售后商品选择': '',
    '首页': '',
    '文章': '',
    '暂无文章数据': '',
    '文章分类': '',
    '最新文章': '',
    '我的余额': '',
    '总金额': '',
    '(元)：': '',
    '可用余额': '',
    '充值': '',
    '提现': '',
    '余额明细': '',
    '充值明细': '',
    '提现明细': '',
    '交易日期': '',
    '收入/支出': '',
    '变动原因': '',
    '暂无余额明细~': '',
    '充值金额': '',
    '(元)': '',
    '暂无充值明细~': '',
    '提现单号': '',
    '提现金额': '',
    '手续费': '',
    '失败原因': '',
    '￥': '',
    '查看': '',
    '暂无提现明细~': '',
    '您尚未设置支付密码，请设置支付密码后再进行后续操作。': '',
    '去设置': '',
    '输入支付密码': '',
    '请输入支付密码以完成后续操作': '',
    '请输入平台支付密码': '',
    '确认': '',
    '绑定手机号': '',
    '请输入验证码': '',
    's后获取': '',
    '获取验证码': '',
    '绑定': '',
    '该手机号已被绑定，请更换其他手机号': '',
    '继续绑定：将解除与账号': '',
    '的绑定关系': '',
    '更新信息：授权信息将绑定到账号': '',
    '继续绑定': '',
    '更新信息': '',
    '品牌中心': '',
    '全选': '',
    '商品信息': '',
    '单价': '',
    '数量': '',
    '小计（元）': '',
    '联系客服': '',
    '满减': '',
    '促销': '',
    '库存': '',
    '库存不足': '',
    '剩余': '',
    '件': '',
    '移入收藏夹': '',
    '确定删除选中商品？': '',
    '删除': '',
    '失效商品': '',
    '清空': '',
    '失效': '',
    '删除选中的商品': '',
    '请输入手机号': '',
    '已选商品': '',
    '合计': '',
    '去结算': '',
    '这里空空如也，快去首页逛逛吧~': '',
    '去首页 > >': '',
    '确定清空失效商品？': '',
    '购物车中还没有商品，赶紧选购吧': '',
    '最新加入的商品': '',
    '共': '',
    '件商品': '',
    '去购物车': '',
    '暂无数据': '',
    '发送链接 >': '',
    '已读': '',
    '未读': '',
    '订单号：': '',
    '常见问题': '',
    '暂未有常见问题~': '',
    '发送': '',
    '我的订单': '',
    '我的足迹': '',
    '店铺推荐': '',
    '发送链接': '',
    '展开全部': '',
    '收起全部': '',
    '暂无订单～': '',
    '暂无足迹～': '',
    '店铺名称：': '',
    '店铺星级：': '',
    '联系电话：': '',
    '店铺评分': '',
    '用户评价：': '',
    '高': '',
    '低': '',
    ' 物流服务：': '',
    '中': '',
    '售后服务：': '',
    '暂无店铺商品～': '',
    '搜索最近联系人': '',
    '搜索': '',
    '确定关闭该对话吗？': '',
    '暂无数据～': '',
    '收藏的商品': '',
    '收藏的店铺': '',
    '取消全选': '',
    '收货地址': '',
    '管理': '',
    '退款金额': '',
    '自营': '',
    '进入店铺': '',
    '加入购物车': '',
    '特别关注': '',
    '普通关注': '',
    '暂无收藏店铺': '',
    '设为特殊关注': '',
    '取消特殊关注': '',
    '取消关注': '',
    '热销推荐': '',
    '本月上新': '',
    '购物车': '',
    '确认订单': '',
    '付款': '',
    '支付成功': '',
    '填写并核对订单信息': '',
    '收货信息': '',
    '新增收货地址': '',
    '收起地址': '',
    '暂无收货地址': '',
    '预留信息': '',
    '请输入': '',
    '商品清单': '',
    '返回修改购物车': '',
    '店铺宝贝': '',
    '小计': '',
    '店铺：': '',
    '虚拟': '',
    '¥': '',
    'x': '',
    '赠品': '',
    '赠完为止': '',
    '给卖家留言：': '',
    '选填，给卖家留言备注': '',
    '使用优惠券': '',
    '暂无可用优惠券': '',
    '成交量': '',
    '不使用优惠券': '',
    '折': '',
    '优惠': '',
    '元': '',
    '商品金额': '',
    '店铺总优惠': '',
    '运费': '',
    '免运费': '',
    '发票': '',
    '我要开发票': '',
    '重新选择': '',
    '使用优惠券/积分抵用': '',
    '平台优惠券': '',
    '积分抵扣': '',
    '固定金额券': '',
    '折扣券': '',
    '随机金额券': '',
    '使用规则：': '',
    '可用积分': '',
    '积分抵现': '',
    '订单金额': '',
    '取消收藏': '',
    '可用': '',
    '当前积分': '',
    '金额抵用': '',
    '张，优惠': '',
    '使用积分': '',
    '分，抵扣': '',
    '商品总额(含运费)': '',
    '共优惠': '',
    '应付金额': '',
    '送货至': '',
    '收货人：': '',
    '提交订单': '',
    '发票历史信息选择': '',
    '默认': '',
    '促销减': '',
    '暂不开发票': '',
    '发票内容': '',
    '商品类别': '',
    '发票抬头': '',
    '公司': '',
    '请在此填写发票抬头': '',
    '发票类型': '',
    '普通发票': '',
    '增值税专用发票': '',
    '单位名称': '',
    '请在此填写单位名称': '',
    '税号': '',
    '请在此填写纳税人识别号': '',
    '注册地址：': '',
    '请输入注册地址': '',
    '注册电话：': '',
    '请输入注册电话': '',
    '开户银行：': '',
    '请输入开户银行': '',
    '银行账户：': '',
    '请输入银行账户': '',
    '收票人姓名': '',
    '请输入收票人姓名': '',
    '收票人电话：': '',
    '请输入收票人电话': '',
    '收票人地址：': '',
    '请输入收票人地址': '',
    '收票邮箱': '',
    '请输入收票邮箱': '',
    '设为默认发票': '',
    '返回': '',
    '移除无货商品': '',
    '继续下单': '',
    '联系方式': '',
    '新增发票': '',
    '积分': '',
    '抵扣': '',
    '使用': '',
    '暂不使用积分': '',
    '使用规则': '',
    '订单大于等于': '',
    '积分支付不超过订单金额的': '',
    '积分使用数量为': '',
    '的整数倍': '',
    '积分等于': '',
    '联系方式：': '',
    '收货地址：': '',
    '更换地址': '',
    '新建地址': '',
    '订单备注': '',
    '给商家留言': '',
    '积分选择': '',
    '暂无可选积分': '',
    '商品金额：': '',
    '实付款：': '',
    '积分 +': '',
    '当前积分数：': '',
    '积分不足': '',
    '发票历史信息选择：': '',
    '发票内容：': '',
    '发票抬头：': '',
    '个人': '',
    '发票类型：': '',
    '单位名称：': '',
    '提交修改': '',
    '注册地址': '',
    '注册电话': '',
    '开户银行': '',
    '银行账户': '',
    '收票人电话': '',
    '收票人地址': '',
    '收票邮箱：': '',
    '我的优惠卷': '',
    '未使用': '',
    '已使用': '',
    '已过期': '',
    '立即使用': '',
    '精选': '',
    '全部': '',
    '随机券': '',
    '领取': '',
    '已领取': '',
    '已抢完': '',
    '已抢': '',
    '已抢光': '',
    '立即领取': '',
    '个人中心': '',
    '订单详情': '',
    '开始付尾款': '',
    '确认收货': '',
    '立即支付': '',
    '评价': '',
    '取消订单': '',
    '修改地址': '',
    '删除订单': '',
    '支付订单': '',
    '商家发货': '',
    '交易关闭': '',
    '送货方式': '',
    '无需物流': '',
    '快递': '',
    '联系电话': '',
    '承运人': '',
    '联系人': '',
    '运单号': '',
    '暂无物流信息': '',
    '订单信息': '',
    '卖家': '',
    '收货人信息': '',
    '交易流水号': '',
    '订单取消备注': '',
    '商品详情': '',
    '商品总额': '',
    '满优惠': '',
    '-¥': '',
    '运费金额': '',
    '店铺优惠券': '',
    '实际金额': '',
    '取消订单理由': '',
    '积分订单详情': '',
    '商品合计': '',
    '已关注': '',
    '关注店铺': '',
    '分享': '',
    'QQ': '',
    '新浪': '',
    '微信': '',
    '分享到微信': '',
    '已兑换': '',
    '价格': '',
    '原价': '',
    '商品已下架，欢迎挑选其他商品~': '',
    '商品已下架': '',
    '热门礼品': '',
    '人气礼品': '',
    '+￥': '',
    '手机下单': '',
    '该商品暂无详情~': '',
    '综合评分': '',
    '描述相符': '',
    '服务态度': '',
    '发货速度': '',
    '服务承诺': '',
    '正品保障': '',
    '客服电话': '',
    '店铺首页': '',
    '请输入关键词': '',
    '搜本店': '',
    '我的购物车': '',
    '本店全部分类': '',
    '所有商品': '',
    '请输入...': '',
    '邮箱管理': '',
    '修改电子邮箱': '',
    '绑定电子邮箱': '',
    '请输入邮箱': '',
    '当前邮箱': '',
    '请输入邮箱验证码': '',
    '请输入新邮箱': '',
    '下一步': '',
    '温馨提示': '',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': '',
    '变更过程中有任何疑问请联系在线客服解决。': '',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': '',
    '评价订单': '',
    '订单：': '',
    '综合': '',
    '商品': '',
    '服务': '',
    '物流': '',
    '': '',
    '商品评分': '',
    '评价晒单': '',
    '请输入内容': '',
    '晒图': '',
    '发表评价': '',
    '交易评价/晒单': '',
    '待评订单': '',
    '发评价，得积分，积少成多换商品': '',
    '评价小贴士': '',
    '发表评价可获得会员积分': '',
    '金额': '',
    '支付金额': '',
    '版权所有': '',
    '提供技术支持': '',
    '收藏': '',
    '已收藏': '',
    '暂无足迹': '',
    '想起密码？': '',
    '去登录': '',
    '找回密码': '',
    '请输入6～20位英文、数字、符号': '',
    '商品服务': '',
    '店铺热销': '',
    '定金预售': '',
    '全款预售': '',
    '距离开始剩余：': '',
    '距离结束剩余：': '',
    '天': '',
    '预售定金¥': '',
    '可抵¥': '',
    '拼团': '',
    '人团': '',
    ' 团长优惠价￥': '',
    '秒杀': '',
    '取消提醒': '',
    '提醒我': '',
    '阶梯团': '',
    '阶梯价': '',
    '拼团价': '',
    '预售价': '',
    '秒杀价': '',
    '预售价 ￥': '',
    '原价 ￥': '',
    '拼团价 ￥': '',
    '阶梯价 ￥': '',
    '秒杀价 ￥': '',
    '销量': '',
    '优惠券': '',
    '领取优惠券': '',
    '(付尾款时间：': '',
    ' (发货时间：': '',
    '配送至': '',
    '请选择地址': '',
    '税号：': '',
    '其他地址': '',
    '扫码参加预售': '',
    '扫码开团': '',
    '原价购买': '',
    '满': '',
    '立即购买': '',
    '看了又看': '',
    '店铺等级': '',
    '商品评价': '',
    '物流评价': '',
    '售后服务': '',
    '热门收藏': '',
    '已售': '',
    '品牌': '',
    '查看全部': '',
    '评分': '',
    '好评率': '',
    '有图': '',
    '好评': '',
    '中评': '',
    '差评': '',
    '商家回复:': '',
    '该商品暂无评论~': '',
    '暂无商品服务~': '',
    '暂无相关商品~': '',
    '全部分类': '',
    '搜索结果': '',
    '评论数': '',
    '商品类型': '',
    '仅平台自营': '',
    '件相关商品': '',
    '人气': '',
    '分类': '',
    '筛选结果': '',
    '包含分类': '',
    '暂无分类': '',
    '热门推荐': '',
    '收起': '',
    '更多选项': '',
    '查看更多': '',
    '亲，抱歉! 您查看的页面失联啦...': '',
    '您可以：去其他地方逛逛': '',
    '商城首页': '',
    '已收藏的商品': '',
    '我们正在努力装修中，敬请期待～': '',
    '会员收货地址': '',
    '超级会员': '',
    '立即开通': '',
    '请选择': '',
    '超级会员已到期': '',
    '立即续费': '',
    '我的财产': '',
    '余额': '',
    '领券': '',
    '店铺关注': '',
    '待支付': '',
    '待收货': '',
    '待评价': '',
    '售后/退货': '',
    '全部订单': '',
    '这里空空如也，快去挑选合适的商品吧~': '',
    '暂无商品~': '',
    '暂无关注店铺~': '',
    '我的收藏': '',
    '暂无收藏商品~': '',
    '暂无足迹~': '',
    '还没注册？': '',
    '账号登录': '',
    '手机登录': '',
    '请输入账号/手机号': '',
    '请输入密码': '',
    '登录': '',
    '二维码已失效': '',
    '点击刷新': '',
    '登录成功': '',
    '打开手机商城，扫描二维码': '',
    '免输入': '',
    '反应快': '',
    '更安全': '',
    '立即注册': '',
    '忘记密码': '',
    '+': '',
    '店铺不存在或已关闭': '',
    '会员信息': '',
    '用户头像': '',
    '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。': '',
    '会员名：': '',
    '真实姓名': '',
    '性别：': '',
    '保密': '',
    '男': '',
    '女': '',
    '昵称：': '',
    '生日：': '',
    '年': '',
    '月': '',
    '日': '',
    '保存': '',
    '待发货': '',
    '已完成': '',
    '订单号/商品名称/店铺名称': '',
    '查看物流': '',
    '物流信息': '',
    '积分订单': '',
    '待付款': '',
    '商品名称/订单编号': '',
    '扫码参团': '',
    '兑换时间：': '',
    '兑换单号: ': '',
    '标记已读': '',
    '确定删除这条消息吗？': '',
    '查看详情 >': '',
    '暂时没有消息！': '',
    '更多': '',
    '向上滑动浏览更多数据': '',
    '数据加载中...': '',
    '数据加载完毕~': '',
    '订单状态': '',
    '常用地址': '',
    '查看权益': '',
    '修改': '',
    '请输入短信验证码': '',
    '请再次输入密码': '',
    '请输入原密码': '',
    '请输入新密码': '',
    '请再次输入新密码': '',
    '复杂的密码可使账号更安全且建议定期更换密码。': '',
    '用户发货': '',
    '商品名称': '',
    '购买数量': '',
    '服务类型': '',
    '物流公司': '',
    '物流单号': '',
    '请输入物流单号': '',
    '退货地址：': '',
    '提交': '',
    '账户管理': '',
    '手机号管理': '',
    '消息': '',
    '收入': '',
    '支出': '',
    '日期': '',
    '详细说明': '',
    '产品分类': '',
    '您好，欢迎来到': '',
    '退出': '',
    '注册': '',
    '待支付订单': '',
    '待收货订单': '',
    '待评价订单': '',
    '商品收藏': '',
    '店铺收藏': '',
    '我的账户': '',
    '我的优惠券': '',
    '官方客服': '',
    '服务中心': '',
    '提现详情': '',
    '申请单号：': '',
    '状态：': '',
    '提现金额：': '',
    '手续费：': '',
    '支付宝账号：': '',
    '真实姓名：': '',
    '申请时间：': '',
    '完成时间：': '',
    '失败原因：': '',
    '余额提现': '',
    '温馨提示：提现手续费为': '',
    '最低提现金额为￥': '',
    '提现方式：': '',
    '请输入提现金额': '',
    '本次提现手续费': '',
    '剩余可提现金额不足': '',
    '剩余可提现金额：': '',
    '请输入支付宝账号': '',
    '请输入真实姓名': '',
    '支付密码：': '',
    '请输入支付密码': '',
    '申请提现': '',
    '订单提交成功，请您尽快付款！ 订单号：': '',
    '请您在提交订单后': '',
    '24小时内': '',
    '完成支付，否则订单会自动取消': '',
    '收起详情': '',
    '展开详情': '',
    '商品名称：': '',
    '使用余额支付': '',
    '（可用余额：': '',
    '元，目前需要在线支付：': '',
    '元）余额不足？': '',
    '马上充值': '',
    '未设置支付密码，马上去设置': '',
    '忘记密码?': '',
    '当前手机号 ': '',
    '微信支付': '',
    '扫码完成支付': '',
    '刷新': '',
    '暂无可用的支付方式，平台正在紧急处理中～': '',
    'APP': '',
    '请先绑定手机号，再进行支付密码操作!': '',
    '当前手机号': '',
    '设置支付密码': '',
    '请输入原支付密码': '',
    '请再次输入新支付密码': '',
    '修改支付密码': '',
    '请输入新手机号': '',
    '账户充值': '',
    '1.填写充值金额': '',
    '2.在线支付': '',
    '3.充值完成': '',
    '填写充值金额': '',
    '充值账户': '',
    '2.充值金额输入值必须是不小于1且不大于5000；': '',
    '3.充值完成后，您可至会员中心查看充值记录。': '',
    '请使用手机微信扫描下方二维码进行支付': '',
    '您正在充值余额，请尽快支付': '',
    '选择充值方式': '',
    '立即充值': '',
    '微信支付支付': '',
    '如二维码过期，': '',
    '重新获取二维码。': '',
    '使用微信扫码支付': '',
    '暂未开启充值功能': '',
    '退款单号': '',
    '退款退货单号': '',
    '重新申请': '',
    '提交申请': '',
    '审核中': '',
    '完成': '',
    '退款凭证': '',
    '平台审核备注': '',
    '时间': '',
    '已有账号？': '',
    '注册账号': '',
    '请输入图形验证码': '',
    '我同意': '',
    '《用户注册协议》': '',
    '《隐私政策》': '',
    '已有账号，去登录': '',
    '重置支付密码': '',
    '请先绑定手机号，再进行重置支付密码操作!': '',
    '请再次输入支付密码': '',
    '接收设置': '',
    '接收': '',
    '不接收': '',
    '暂时没有数据～': '',
    '人购买': '',
    '立即抢购': '',
    '此处添加【403*高度不限】图片': '',
    '请先绑定手机号，再进行登陆密码操作!': '',
    '添加内容': '',
    '图片标题': '',
    '此处添加【186*340】图片': '',
    '图片子标题': '',
    '此处添加【172*106】图片': '',
    '此处添加【187*120】图片': '',
    '个商品': '',
    '关注': '',
    '人已收藏': '',
    '店铺已售': '',
    '本店暂无商品~': '',
    '请输入店铺名称': '',
    '确定删除该地址?': '',
    '提示': '',
    '仅退款': '',
    '退货退款': '',
    '未收到货': '',
    '已收到货': '',
    '退款退货': '',
    '选择其它支付方式': '',
    '退款金额不可为0或者负值！': '',
    '不可超过最大退款金额！': '',
    '最多上传5张！': '',
    '提现功能已关闭': '',
    '密码输入错误，请重新输入': '',
    '最低限购1件!': '',
    '数量超出购买范围！': '',
    '超过购买限制!': '',
    '移入收藏夹成功！': '',
    '删除成功！': '',
    '清空失效商品成功！': '',
    '结算商品不能为空！': '',
    '在线': '',
    '请选择图片类型文件': '',
    '是否要发送剪切板的图片?': '',
    '账号已登出': '',
    'warning': '',
    '离线': '',
    '[图片]': '',
    '[商品]': '',
    '[订单]': '',
    '确定取消收藏吗?': '',
    '请选择商品': '',
    '邮箱格式不正确，请重新输入！': '',
    '请填写正确的税号': '',
    '请填正确填写注册电话': '',
    '请填正确填写收票人电话': '',
    '请填写发票抬头': '',
    '请填写收票邮箱': '',
    '请填写单位名称': '',
    '设置': '',
    '添加标题': '',
    '请填写注册电话': '',
    '请正确填写注册电话': '',
    '请填写开户银行': '',
    '不可超出最大申请数量': '',
    '请填写收票人姓名': '',
    '请填写收票人电话': '',
    '请正确填写收票人电话': '',
    '请填写收票人地址': '',
    '商品促销信息发生变化，请返回后重新下单': '',
    '商品促销信息发生变化': '',
    '提交订单失败，请稍后重试': '',
    ',2s后自动跳转订单列表': '',
    '请输入正确的': '',
    '领取成功': '',
    '取消该订单定金不予退还,确定取消?': '',
    '请选择取消理由': '',
    '取消订单成功': '',
    '确认删除该订单?': '',
    '删除订单成功': '',
    '确认收货?': '',
    '确认收货成功': '',
    '切换地址成功': '',
    '下单': '',
    '请输入邮箱号': '',
    '评价成功': '',
    '上传图片失败': '',
    '确定要清空吗?': '',
    '从商品详情页进入': '',
    ' 点这里': '',
    '请输入正确的账号/手机号': '',
    '上传图片成功': '',
    '图像应小于4MB': '',
    '从订单列表进入': '',
    '消息列表': '',
    '请填写纳税人税号': '',
    '订单消息': '',
    '资产消息': '',
    '售后消息': '',
    '预约提醒': '',
    '确定删除这些消息吗': '',
    '请先选择要操作的消息！': '',
    '请先绑定手机号，再进行操作!': '',
    '请输入旧密码': '',
    '请输入一致的新密码': '',
    '请输入一致的密码': '',
    '请填写银行账户': '',
    '请输入物流单号！': '',
    '请输入正确的物流单号！': '',
    '请输入正确的支付密码': '',
    '请选择支付方式': '',
    '支付成功,2s后自动跳转订单列表': '',
    '请输入旧支付密码': '',
    '请输入新支付密码': '',
    '请输入一致的新支付密码': '',
    '请输入一致的支付密码': '',
    '请输入充值金额': '',
    '超过充值金额上限': '',
    '充值成功,2s后自动跳转充值列表': '',
    '充值成功,2s后自动跳转支付页面': '',
    '请同意用户注册协议及隐私政策': '',
    '从店铺详情页进入': '',
    '请填写注册地址': '',
    '请选择物流公司!': '',
    '系统消息': '',
    '请输入5~40个字符': ''
};
